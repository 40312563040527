import React from "react";
import { GPT4SalesIcon } from "../../components/Icons/Icons.jsx";
import "./SocialMediaEmbed.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";

const SocialMediaPage = () => {
  const { t } = useTranslation();
  const openInNewWindow = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="social-media-page">
      <GPT4SalesIcon color={"#aa5de7"} height={"128px"} />
      <p className="tagline">
        {t("intro")}
        <br /> {t("intro2")}
      </p>
      <button
        className="open-in-new-tab-button"
        onClick={() => openInNewWindow("http://www.gpt4sales.ai")}
      >
        {t("openInNewTab")}{" "}
        <OpenInNewIcon sx={{ marginLeft: "3%", width: "4%" }} />
      </button>
    </div>
  );
};

export default SocialMediaPage;
