import { createContext, useState } from "react";

export const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [ModalActive, setModalActive] = useState(false);
  const [lang, setLang] = useState("en");

  const [payFormModal, setPayFormModal] = useState(false);

  return (
    <DataContext.Provider
      value={{
        ModalActive,
        setModalActive,
        lang,
        setLang,
        payFormModal,
        setPayFormModal,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
