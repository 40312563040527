import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect, lazy, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import UserContex from "./context/UserContext";
import MyBotContext from "./context/MyBotContext";
import { DataProvider } from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import i18n from "i18next";
import { BACKEND_URL } from "./config";

import SocialMediaPage from "./pages/SocialMediaEmbed";
import CalendarCallback from "./components/CalendarCallback";
import PaymentSuccess from "./components/SuccessPayment";

// Lazy loading components
const Mybots = lazy(() => import("./components/Mybots"));
const CreateBot = lazy(() => import("./components/CreateBot"));
const Account = lazy(() => import("./components/Account"));
const LoginPage = lazy(() => import("./pages/login"));
const SignupPage = lazy(() => import("./pages/signup"));
const ForgotPage = lazy(() => import("./pages/forgot"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./components/Refund"));
const TermsAndConditions = lazy(
  () => import("./components/TermsAndConditions"),
);
const LandingPage = lazy(() => import("./components/LandingPage"));

const CRM = lazy(() => import("./pages/CRM"));

function App() {
  const [userData, setUserData] = useState({
    email: localStorage.getItem("email"),
    token: localStorage.getItem("token"),
    loggedWith: localStorage.getItem("loggedWith") || "email",
  });

  const [myBotData, setMyBotData] = useState(null);

  const [registerFinished, setRegisterFinished] = useState(false);

  useEffect(() => {
    const sessionInfoListener = async (event) => {
      if (event.origin !== "https://www.facebook.com") {
        return;
      }

      let data;

      try {
        data = JSON.parse(event.data);
      } catch (error) {
        console.error("Received non-JSON event data:", event.data);

        return;
      }

      try {
        if (data.type === "WA_EMBEDDED_SIGNUP" && data.event === "FINISH") {
          const botId = localStorage.getItem("currentBotId");
          const { phone_number_id, waba_id } = data.data;

          try {
            const response = await fetch(
              `${BACKEND_URL}/update-whatsapp-info`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  botId,
                  phone_number_id,
                  waba_id,
                }),
              },
            );
            const responseData = await response.json();
            console.log(responseData);
            setRegisterFinished(true);
          } catch (error) {
            console.error("Error updating WhatsApp info:", error);
          }
        } else if (data.event !== "FINISH") {
          const { current_step } = data.data;
          console.log("User cancelled the Embedded Signup flow", current_step);
        }
      } finally {
        console.log("event data:", event.data, event.origin);
      }
    };

    window.addEventListener("message", sessionInfoListener);
    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    const response = localStorage.getItem("selectedLanguage");
    const getLanguage = i18n.language;
    const language = getLanguage.split("-")[0];
    if (response) {
      i18n.changeLanguage(response);
    } else if (language !== "es" && language !== "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage(language);
    }
  }, []);

  return (
    <DataProvider>
      <UserContex.Provider value={[userData, setUserData]}>
        <MyBotContext.Provider value={[myBotData, setMyBotData]}>
          <div className="App">
            <Router>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<LandingPage />} />

                  <Route
                    path="/app"
                    element={<Mybots registerFinished={registerFinished} />}
                  />
                  <Route path="/app/login" element={<LoginPage />} />
                  <Route path="/app/signup" element={<SignupPage />} />
                  <Route
                    path="/app/my-bots"
                    element={<Mybots registerFinished={registerFinished} />}
                  />
                  <Route path="/app/set-password" element={<SignupPage />} />
                  <Route path="/app/create-bot" element={<CreateBot />} />
                  <Route path="/app/account" element={<Account />} />
                  <Route path="/app/forgot" element={<ForgotPage />} />
                  <Route
                    path="/app/reset-password/:id/:token"
                    element={<ResetPassword />}
                  />

                  <Route path="/login" element={<Navigate to="/app/login" />} />
                  <Route
                    path="/set-password"
                    element={<Navigate to="/app/signup" />}
                  />
                  <Route
                    path="/app/set-password"
                    element={<Navigate to="/app/signup" />}
                  />
                  <Route
                    path="/my-bots"
                    element={<Mybots registerFinished={registerFinished} />}
                  />
                  <Route
                    path="/create-bot"
                    element={<Navigate to="/app/create-bot" />}
                  />
                  <Route
                    path="/account"
                    element={<Navigate to="/app/account" />}
                  />
                  <Route
                    path="/forgot"
                    element={<Navigate to="/app/forgot" />}
                  />

                  <Route path="/social" element={<SocialMediaPage />} />
                  <Route
                    path="/calendar-success"
                    element={<CalendarCallback />}
                  />
                  <Route
                    path="/successfulpayment"
                    element={<PaymentSuccess />}
                  />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsAndConditions />} />
                  <Route path="/refund-policy" element={<RefundPolicy />} />
                  <Route path="/crm" element={<CRM />} />
                </Routes>
              </Suspense>
            </Router>
          </div>
          <ToastContainer position="bottom-left" />
        </MyBotContext.Provider>
      </UserContex.Provider>
    </DataProvider>
  );
}

export default App;
