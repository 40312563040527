import React from "react";
import { GPT4SalesIcon } from "../Icons/Icons.jsx";
import "./CalendarCallback.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";
import { GoogleCalendarIcon } from "../../assets/img/googleCalendarIcon.jsx";
import CloseIcon from "@mui/icons-material/Close";

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const openInNewWindow = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="social-media-page">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <GPT4SalesIcon color={"#aa5de7"} height={"100px"} />
      </div>
      <p className="tagline">
        {t("paymentSuccess")}
        <br /> {t("calendarSuccess2")}
      </p>
      <button
        className="open-in-new-tab-button"
        onClick={() => openInNewWindow("http://www.gpt4sales.ai")}
      >
        {t("returnToGPT4Sales")}
        <OpenInNewIcon sx={{ marginLeft: "3%", width: "4%" }} />
      </button>
    </div>
  );
};

export default PaymentSuccess;
